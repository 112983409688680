body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Noto Sans JP", sans-serif;
  background-color: #EAEDED;
  margin: 0; 
  height: 100%; 
  overflow: hidden;
}
*:focus-visible {
  outline: none;
}
pre {
  font-family: "Noto Sans JP", sans-serif;
}
// simplebar scrollbar start
.simplebar-wrapper{
  padding: 0 !important;
}
.simplebar-track{
  padding: 0 7px !important;
}
.simplebar-scrollbar{
  &::before{
    background-color: #DDDDDD;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    border-radius: 6px;
  }
}
.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}
.simplebar-track.simplebar-vertical{
  .simplebar-scrollbar{
    &::before{
      top: 4px;
      bottom: 4px;
    }
  }
}
// .simplebar-track.simplebar-horizontal {
//   height: 11px;
// }
// .simplebar-track.simplebar-horizontal .simplebar-scrollbar{
//   height: 7px;
//   top: -2px;
// }
.simplebar-track.simplebar-horizontal {
  .simplebar-scrollbar {
    &::before {
      top: 0px;
      bottom: 0px;
    }
  }
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar.simplebar-hover{
  cursor: pointer;
}

.indicatorSeparator {
  width: 0px !important;
}