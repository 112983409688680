.container {
  display: flex;
  gap: 20px;
  font-size: 14px;
}

.card_left {
  display: grid;
  grid-template-columns: 38.28% 33.7% 28.08%;
  grid-template-rows: repeat(3, 46px) 51px;
  max-width: 785px;
  row-gap: 40px;
  width: 50%;
  padding: 24px 32px 30px 32px;
}

.card_right {
  display: grid;
  grid-template-columns: 38.82% 1fr;
  max-width: 785px;
  row-gap: 45px;
  width: 50%;
  max-height: 256px;
  height: auto;
  min-height: 100px;
  align-self: flex-start;
  padding: 24px 37px 30px;
  grid-column: span 2;

  &_suspension {
    position: relative;
    display: flex;
    align-items: center;
    &_warning {
      color: #e1341e;
      font-size: 10px;
      display: flex;
      align-items: center;
      margin-top: 1px;
      &_icon {
        display: inline-block;
        padding: 2px 1px 2px 2px;
        margin-top: 1px;
        margin-right: 2px;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .container {
    font-size: 11px !important;
    // background-color: blue;
  }
}
