$cellheight: 30px;
$cellwidth: 36px;

.datepicker {
  font-size: 14px;
  font-family: 'Noto Sans JP';
  border-radius: 12px;
  // width: auto;
  position: relative;
  &_input {
    display: flex;
    width: 292px;
    // height: 18px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    // outline: 1px solid #d9d9d9;
    outline: none;
    padding: 10px 16px;
    // width: 100%;
    font-family: 'Noto Sans JP', sans-serif;
    height: 38px;
    font-size: 14px;
    color: #232F3E;
    &:hover {
      cursor: pointer;
      border: 1px solid #9AA5B1;
    }
    &:focus {
      border: 1px solid #9AA5B1;
    }
  }
  &_modal {
    position: absolute;
    background-color: white;
    min-height: 371px;
    box-shadow: 0px 6px 16px #0000001f;
    z-index: 1;
    width: 342px;
    &_header {
      font-size: 14px;
      width: 296px;
      width: 100%;
      &_divider {
        margin: 17px auto 20px auto;
        width: 251px;
        border-top: 1px solid #d9d9d9;
      }
      &_buttons {
        padding: 25px 23px 0px 23px;
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        & button {
          background-color: transparent;
          color: #939598;
          font-weight: bold;
          border: none;
          cursor: pointer;
          &:focus {
            outline: 1px solid #009de1;
          }
          &:hover {
            outline: 1px solid #009de1;
          }
        }
      }
    }
    &_calendar {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      &_week {
        display: grid;
        grid-template-columns: repeat(7, $cellwidth);
        gap: 4px;
        font-size: 10px;
        &_day {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          width: $cellwidth;
          height: 14px;
          vertical-align: middle;
          color: #939598;
        }
      }
      &_date {
        margin-top: 16px;
        display: grid;
        grid-template-columns: repeat(7, $cellwidth);
        gap: 4px;
        &_day {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          width: $cellwidth;
          height: $cellheight;
          border-radius: 4px;
          font-family: 'Noto Sans JP', sans-serif;
          &-normal {
            cursor: pointer;
            background-color: transparent;
            border: none;
            font-family: 'Noto Sans JP', sans-serif;
            padding: 5px;
            border-radius: 4px;
            &:hover {
              background-color: #009de1;
              color: white;
            }
            &:focus {
              background-color: #009de1;
              color: white;
              outline: none;
            }
          }
          &-selected {
            @extend .datepicker_modal_calendar_date_day-normal;
            background-color: #009de1;
            color: white;
            cursor: pointer;
          }
          &-current {
            @extend .datepicker_modal_calendar_date_day-normal;
            outline: 1px solid #009de1;
            border-radius: 4px;
            border: none;
            cursor: pointer;
          }
          &-disabled {
            @extend .datepicker_modal_calendar_date_day-normal;
            background-color: #bfc1c2;
            color: white;
            cursor: pointer;
            &:hover {
              background-color: #bfc1c2;
              color: white;
              cursor: default;
            }
            &:focus {
              // background-color: #009de1;
              background-color: #bfc1c2;
              color: white;
              outline: none;
            }
          }
        }
      }
    }
    &_time {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 16px;
      & select {
        width: 50px;
        height: 30px;
        text-align: center;
        border-radius: 4px;
        border: none;
        background-color: #eaeded;
        font-family: 'Noto Sans JP', sans-serif;
        &:focus,
        &:hover {
          cursor: pointer;
          outline: 1px solid black;
        }
      }
    }
    &_footer {
      font-size: 16px;
      &_divider {
        margin: 16px auto 10px auto;
        width: 100%;
        border-top: 1px solid #d9d9d9;
      }
      &_content {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0 15px 10px 15px;
        &_text {
          font-size: 14px;
        }
        &_buttons {
          display: flex;
          gap: 8px;
          & button {
            font-size: 14px;
            padding: 0 20px;
            border-radius: 6px !important;
            height: 38px;
            font-weight: 400 !important;
            cursor: pointer;
            font-family: 'Noto Sans JP', sans-serif;
          }
          &-cancel {
            color: #232f3e !important;
            border: 1px solid #D9D9D9 !important;
            // height: 38px;
            padding: 10px 20px;
            margin: 0 0 0 8px;
            text-transform: none;
            transition: background-colors 0.25s;
            &:hover {
              background-color: #FAFAFA;
              border: 1px solid #D1D1D1 !important;
              color: #000 !important;
            }
            &:focus {
              outline: 4px solid #CCEBF9;
            }
          }
          &-confirm {
            color: #ffffff !important;
            background-color: #007eb4 !important;
            border: 1px solid #0078ab !important;
            text-transform: none;
            &:hover {
              background-color: #006D9C !important;
              border: 1px solid #0078ab !important;
            }
            &:focus {
              outline: 5px solid #CCEBF9 !important ;
            }
          }
        }
      }
    }
  }
}

.current-day {
  outline: 1px solid #009de1;
  border-radius: 4px;
  border: none;
}

.selected-day {
  background-color: #009de1;
  color: white;
}
.time-divider {
  margin-left: 5px;
  margin-right: 5px;
}
