@keyframes loaderInner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loaderInner div {
  left: 46px;
  top: 2px;
  position: absolute;
  animation: loaderInner linear 1s infinite;
  background: #fff;
  width: 10px;
  height: 26px;
  border-radius: 3.64px / 3.64px;
  transform-origin: 4px 48px;
}
.loaderInner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.875s;
  // background: #fff;
}
.loaderInner div:nth-child(2) {
  transform: rotate(45deg);
  animation-delay: -0.75s;
  // background: #fff;
}
.loaderInner div:nth-child(3) {
  transform: rotate(90deg);
  animation-delay: -0.625s;
  // background: #fff;
}
.loaderInner div:nth-child(4) {
  transform: rotate(135deg);
  animation-delay: -0.5s;
  // background: #fff;
}
.loaderInner div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -0.375s;
  // background: #fff;
}
.loaderInner div:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: -0.25s;
  // background: #fff;
}
.loaderInner div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.125s;
  // background: #fff;
}
.loaderInner div:nth-child(8) {
  transform: rotate(315deg);
  animation-delay: 0s;
  // background: #fff;
}
.loaderWrapper {
  width: 20px;
  height: 20px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.loaderInner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.2);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.loaderInner div {
  box-sizing: content-box;
}

.medium {
  width: 30px;
  height: 30px;
}
.medium .loaderInner {
  transform: translateZ(0) scale(0.3);
}

