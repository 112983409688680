.container {
  display: flex;
  gap: 20px;
  font-size: 14px;
}

.card_left {
  display: grid;
  grid-template-columns: 38.28% 33.7% 28.08%;
  grid-template-rows: repeat(3, 46px) 51px;
  max-width: 785px;
  row-gap: 36px;
  width: 50%;
  padding: 24px 32px 30px 32px;
}

.card_right {
  display: grid;
  grid-template-columns: 38.82% 1fr;
  max-width: 785px;
  row-gap: 45px;
  width: 50%;
  max-height: 256px;
  height: auto;
  min-height: 100px;
  align-self: flex-start;
  padding: 24px 37px 30px;
  grid-column: span 2;

  &_suspension {
    position: relative;
    display: flex;
    &_warning{
        color: #E1341E; 
        font-size: 10px; 
        position: absolute; 
        top: 20px;
        &_icon{
            width: 14px; 
            display: inline-block;
        }
    }
  }
}

@media screen and (max-width: 1250px) {
    .container{
        font-size: 11px !important;
        // background-color: blue;
    }
}