$icons: (
  // iconList
  'corporation',
  'device',
  'import',
  'usergroup',
  'notification',
  'eula',
  'shipping-data-import',
  'device-urlmap',
  'import-icon-up',
  'region-mapping'
);

.a-icon {
  $root: &;
  width: 18px;
  height: 18px;

  display: inline-block;

  @each $icon-name in $icons {
    &--#{$icon-name} {
      -webkit-mask-image: url('../../../images/icon/#{$icon-name}.svg');
      mask-image: url('../../../images/icon/#{$icon-name}.svg');
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: contain;
      mask-size: contain;
      background-image: unset;
      background-color: white;
    }

    &--#{$icon-name}-mask {
      background-color: currentColor;
      -webkit-mask-image: url('../../../images/icon/#{$icon-name}.svg');
      mask-image: url('../../../images/icon/#{$icon-name}.svg');
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: contain;
      mask-size: contain;
      background-image: unset;

      //   &:hover {
      //       background-color: #007EB4;
      //   }
    }
  }
}

.MuiListItemButton-root {
  @each $icon-name in $icons {
    &:hover .a-icon--#{$icon-name}-mask {
      color: #007eb4;
    }
  }
}
